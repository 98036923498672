import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'src/assets/js/axiosInstance'
import common from './assets/js/common'
const Guide = (WrappedComponent) => {
  const navigate = useNavigate()
  const Component = (props) => {
    const adminMenu = !!props.admin ? props.admin : false
    useEffect(() => {
      getAuthentication()
    }, [])
    const getAuthentication = async () => {
      const sessionKey = common.getSessionValue('key')
      const sessionSu = common.getSessionValue('su')
      await axios.post('/api/auth/authentication', { key: sessionKey }).then((response) => {
        const result = response.data
        if (result.code == '201') {
          const adminYn = result.data.admin_yn == 1 ? true : false
          if (adminMenu) {
            if (result.data.id != sessionKey || adminYn != sessionSu || adminYn != true) {
              window.alert('잘못된 접근입니다.')
              navigate('/login', { replace: true })
              sessionStorage.removeItem('session:info')
            }
          } else {
            if (result.data.id != sessionKey) {
              window.alert('잘못된 접근입니다.')
              navigate('/login', { replace: true })
              sessionStorage.removeItem('session:info')
            }
          }
        } else {
          window.alert('잘못된 접근입니다.')
          navigate('/login', { replace: true })
          sessionStorage.removeItem('session:info')
        }
      })
    }
    return <WrappedComponent {...props} />
  }
  return Component
}

export default Guide
