import { createSlice } from '@reduxjs/toolkit'
const intiState = {
  sidebarShow: true,
}
export const sidebarShowSlice = createSlice({
  name: 'sidebarShow',
  initialState: intiState,
  reducers: {
    setSidebarShow: (state, action) => {
      state.sidebarShow = action.payload.sidebarShow
    },
  },
})
export const { setSidebarShow } = sidebarShowSlice.actions
export default sidebarShowSlice.reducer
