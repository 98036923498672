import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import { Provider } from 'react-redux'
import store from './redux/store'

import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'

import reportWebVitals from './reportWebVitals'

import axios from 'src/assets/js/axiosInstance'

import common from 'src/assets/js/common'

export let persistor = persistStore(store)
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
window.addEventListener('visibilitychange', async () => {
  if (document.hidden) {
    const loginId = common.getSessionValue('id')
    if (!!loginId) {
      const data = {
        loginId: common.getSessionValue('id'),
        notiType: '-1',
        alertYn: 'N',
        title: 'MIR-Med 시스템을 항상 활성화 시켜주세요',
        body: 'MIR-Med 시스템이 비활성화 되어있으면 알림이 울리지 않습니다.',
      }
      await axios.post('/api/manage/send/push/notification', data).catch((error) => {
        console.error('푸시알림 전송 실패', error)
      })
    }
  }
})
createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <PersistGate loading={loading} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
