import React, { Suspense, useState, useEffect } from 'react'
import { Navigate, useNavigate, Route, Routes } from 'react-router-dom'
import Guider from './Guider'
import './scss/style.scss'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/login/Login'))

const Router = () => {
  return (
    <Suspense fallback={loading}>
      <Routes>
        <Route path="/" name="Login Page" element={<Navigate to="/login" replace />} />
        <Route path="/login" name="Login Page" element={<Login admin={null} />} />
        <Route path="*" name="Home" element={<DefaultLayout />} />
      </Routes>
    </Suspense>
  )
}

export default Router
