import axios from 'src/assets/js/axiosInstance'
import React, { useRef, useState } from 'react'
import { getMessaging, onMessage } from 'firebase/messaging'
import app from './firebase'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import NotificationSound from './alert_sound.mp3'
import PatientAssessPopup from 'src/views/patient/PatientAssess'
import AlertPopup from './AlertPopup'
import common from 'src/assets/js/common'
import { useDispatch } from 'react-redux'
import { setCount } from 'src/redux/unReadAlarmSlice'
import dayjs from 'dayjs'
const PushArea = () => {
  const dispatch = useDispatch()
  const messaging = getMessaging(app)
  const audioPlayer = useRef(null)
  const visibleRef = useRef('none')
  const [visible, setVisible] = useState('none')
  //경고 알림 팝업
  const alertPopupRef = useRef()
  //환자평가 팝업
  const [visibleAssessModal, setVisibleAssessModal] = useState(false)
  const [patientAssessmentId, setPatientAssessmentId] = useState('')
  const [patientAssessmentNickname, setPatientAssessmentNickname] = useState('')
  const patientAssessRef = useRef({})
  const visibleAssessModalRef = useRef(false)
  //전역 변수
  const [messageObject, setMessageObject] = useState({})
  const messageObjectRef = useRef({})
  const [gToastId, setGtoastId] = useState('')
  const [gTimeoutId, setGtimeoutId] = useState('')
  const gToastIdRef = useRef('')
  const gTimeoutIdRef = useRef('')
  React.useEffect(() => {
    visibleRef.current = visible
  }, [visible])
  React.useEffect(() => {
    gToastIdRef.current = gToastId
  }, [gToastId])
  React.useEffect(() => {
    gTimeoutIdRef.current = gTimeoutId
  }, [gTimeoutId])
  React.useEffect(() => {
    visibleAssessModalRef.current = visibleAssessModal
  }, [visibleAssessModal])
  React.useEffect(() => {
    messageObjectRef.current = messageObject
    //소리 중지 및 경고화면 끄기
    if (Object.keys(messageObject).length === 0) {
      audioPlayer.current.pause()
      setVisible('none')
    }
    // 번쩍이는 효과 끄기
    let flag = 0
    for (const obj of Object.keys(messageObject)) {
      if (messageObject[obj].type === '1') {
        flag++
      }
    }
    if (flag === 0) {
      const timeoutId = gTimeoutIdRef.current
      for (let i = 0; i < 10004; i++) {
        clearTimeout(timeoutId - i)
      }
    }
  }, [messageObject])
  const alertAfterProcess = (toastId) => {
    const messageObjectCurrent = messageObjectRef.current
    if (Object.keys(messageObjectCurrent).length > 0) {
      if (!!messageObjectCurrent[toastId]) {
        toast.dismiss(toastId)
        setMessageObject((messageObjectCurrent) => {
          let newCondition = { ...messageObjectCurrent }
          delete newCondition[toastId]
          return newCondition
        })
      }
    } else {
      const timeoutId = gTimeoutIdRef.current
      for (let i = 0; i < 10004; i++) {
        clearTimeout(timeoutId - i)
      }
    }
  }
  const getUnReadAlarmCount = async () => {
    const axiosResult = await axios.get('/api/notification/unread/count', {
      params: {
        institutionId: common.getSessionValue('key'),
      },
    })
    if (axiosResult.data.code === 200) {
      dispatch(setCount(axiosResult.data.data.count))
    }
  }
  React.useEffect(() => {
    onMessage(messaging, async (message) => {
      const options = {
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        draggable: false,
        position: toast.POSITION.BOTTOM_RIGHT,
        pauseOnHover: true,
      }
      let toastId = ''

      if (message.data.type === '1') {
        // patientNotificationId
        // type
        // alertYn
        // patientId
        // patientName
        // title
        // body
        getUnReadAlarmCount()
        options.theme = 'colored'
        options.type = toast.TYPE.ERROR
        toastId = `${message.data.patientId}alert${message.data.patientNotificationId}`
        setMessageObject((messageObject) => {
          let newCondition = { ...messageObject }
          newCondition[toastId] = message.data
          newCondition[toastId].toastId = toastId
          return newCondition
        })
        alertPopupRef.current.setNotificationId(message.data.patientNotificationId)
        alertPopupRef.current.setNotificationType(message.data.type)
        alertPopupRef.current.setPatientId(message.data.patientId)
        setPatientAssessmentId(message.data.patientId)
        setPatientAssessmentNickname(message.data.patientName)
        options.toastId = toastId
      } else if (message.data.type === '0') {
        // notificationId
        // type
        // alertYn
        // title
        // body
        getUnReadAlarmCount()
        options.type = toast.TYPE.INFO
        toastId = `info${message.data.notificationId}`
        setMessageObject((messageObject) => {
          let newCondition = { ...messageObject }
          newCondition[toastId] = message.data
          newCondition[toastId].toastId = toastId
          return newCondition
        })
        alertPopupRef.current.setNotificationId(message.data.notificationId)
        alertPopupRef.current.setNotificationType(message.data.type)
        options.toastId = toastId
      } else if (message.data.type === '-1') {
        // type
        // alertYn
        // title
        // body
        options.type = toast.TYPE.DEFAULT
        toastId = `test${Math.floor(Math.random() * 101)}`
        setMessageObject((messageObject) => {
          let newCondition = { ...messageObject }
          newCondition[toastId] = message.data
          newCondition[toastId].toastId = toastId
          return newCondition
        })
        options.toastId = toastId
      }
      setGtoastId(toastId)
      if (message.data.alertYn !== 'N') {
        options.onOpen = (props) => {
          if (messageObjectRef.current[toastId].type === '1') {
            audioPlayer.current.play()
            if (!!gTimeoutIdRef.current) {
              const timeoutId = gTimeoutIdRef.current
              for (let i = 0; i < 10004; i++) {
                clearTimeout(timeoutId - i)
              }
            }
            for (let i = 0; i < 10000; i++) {
              ;((x) => {
                const timeoutTemp = setTimeout(() => {
                  if (visibleAssessModalRef.current === false) {
                    if (i === 9999) {
                      setVisible('none')
                    } else {
                      if (visibleRef.current === 'none') {
                        setVisible('block')
                      } else if (visibleRef.current === 'block') {
                        setVisible('none')
                      }
                    }
                  } else {
                    setVisible('none')
                  }
                }, 500 * x)
                setGtimeoutId(timeoutTemp)
              })(i)
            }
          }
        }
        options.onClose = (props) => {
          setTimeout(() => {
            alertAfterProcess(toastId)
          }, 50)
        }
        options.onClick = (props) => {
          if (visibleAssessModalRef.current === true) {
            alert('환자 평가를 먼저 진행해주세요.')
            return false
          }
          if (Object.keys(messageObjectRef.current).length > 0) {
            const messageObjectCurrent = messageObjectRef.current[props.target.id]
            if (!!messageObjectCurrent) {
              if (messageObjectCurrent.type === '1') {
                alertPopupRef.current.setNotificationId(message.data.patientNotificationId)
                alertPopupRef.current.setNotificationType(message.data.type)
                alertPopupRef.current.setPatientId(message.data.patientId)
                setPatientAssessmentId(messageObjectCurrent.patientId)
                setPatientAssessmentNickname(messageObjectCurrent.patientName)
              } else if (messageObjectCurrent.type === '0') {
                alertPopupRef.current.setNotificationId(message.data.notificationId)
                alertPopupRef.current.setNotificationType(message.data.type)
              }
              alertPopupRef.current.setRealCrisisModalTitle(messageObjectCurrent.title)
              alertPopupRef.current.setRealCrisisModalBody(messageObjectCurrent.body)
              alertPopupRef.current.setRealCrisisModalAction(messageObjectCurrent.type)
              alertPopupRef.current.setNotificationDateTime(messageObjectCurrent.createdAt)
              alertPopupRef.current.setAlertMute(false)
              alertPopupRef.current.setOpen(true)
              setGtoastId(messageObjectCurrent.toastId)
            }
          }
        }
        alertPopupRef.current.setRealCrisisModalTitle(message.data.title)
        alertPopupRef.current.setRealCrisisModalBody(message.data.body)
        alertPopupRef.current.setRealCrisisModalAction(message.data.type)
        alertPopupRef.current.setNotificationDateTime(message.data.createdAt)
        alertPopupRef.current.setAlertMute(false)
        alertPopupRef.current.setOpen(true)
      }
      if (message.data.type === '1') {
        toast.error(({ closeToast }) => <div id={toastId}>{message.data.title}</div>, options)
      } else if (message.data.type === '0') {
        toast.info(({ closeToast }) => <div id={toastId}>{message.data.title}</div>, options)
      } else if (message.data.type === '-1') {
        toast.error(({ closeToast }) => <div id={toastId}>{message.data.title}</div>, options)
      }
    })
  }, [])
  return (
    <>
      <AlertPopup
        ref={alertPopupRef}
        setVisibleAssessModal={setVisibleAssessModal}
        alertAfterProcess={alertAfterProcess}
        pToastId={gToastIdRef}
        audioPlayer={audioPlayer}
        messageObjectRef={messageObjectRef}
      />
      <PatientAssessPopup
        ref={patientAssessRef}
        setVisible={setVisibleAssessModal}
        visible={visibleAssessModal}
        setPatientAssessmentId={setPatientAssessmentId}
        patientAssessmentId={patientAssessmentId}
        setPatientAssessmentNickname={setPatientAssessmentNickname}
        patientAssessmentNickname={patientAssessmentNickname}
      />
      <div
        style={{
          position: 'absolute',
          width: '100vw',
          height: '100vh',
          left: '0px',
          top: '0px',
          backgroundColor: 'red',
          opacity: '0.6',
          zIndex: '8000',
          display: visible,
        }}
      ></div>
      <audio ref={audioPlayer} autoPlay={false} src={NotificationSound} loop={true} />
      <ToastContainer pauseOnFocusLoss />
    </>
  )
}

export default PushArea
