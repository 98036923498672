import { createSlice } from '@reduxjs/toolkit'
const intiState = {
  unfoldable: false,
}
export const unfoldableSlice = createSlice({
  name: 'unfoldable',
  initialState: intiState,
  reducers: {
    setUnfoldableSlice: (state, action) => {
      state.unfoldable = action.payload.unfoldable
    },
  },
})
export const { setUnfoldableSlice } = unfoldableSlice.actions
export default unfoldableSlice.reducer
