import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Router from './Router'
import PushArea from './PushArea'
const App = () => {
  return (
    <BrowserRouter>
      <PushArea />
      <Router />
    </BrowserRouter>
  )
}

export default App
