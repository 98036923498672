import axios from 'axios'
import common from './common'
// axios 인스턴스 생성
const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_SERVER_URL,
  withCredentials: true,
})

/*
    1. 요청 인터셉터를 작성합니다.
    2개의 콜백 함수를 받습니다.

    1) 요청 바로 직전 - 인자값: axios config
    2) 요청 에러 - 인자값: error
*/
instance.interceptors.request.use(
  (config) => {
    // 요청 바로 직전
    // axios 설정값에 대해 작성합니다.
    const sessionAccessToken = common.getSessionValue('accessToken')
    config.headers.Authorization = sessionAccessToken ? `Bearer ${sessionAccessToken}` : 'Bearer '
    return config
  },
  (error) => {
    // 요청 에러 처리를 작성합니다.
    return Promise.reject(error)
  },
)

/*
    2. 응답 인터셉터를 작성합니다.
    2개의 콜백 함수를 받습니다.

    1) 응답 정성 - 인자값: http response
    2) 응답 에러 - 인자값: http error
*/
instance.interceptors.response.use(
  (response) => {
    /*
        http status가 200인 경우
        응답 바로 직전에 대해 작성합니다.
        .then() 으로 이어집니다.
    */
    return response
  },

  async (error) => {
    /*
        http status가 200이 아닌 경우
        응답 에러 처리를 작성합니다.
        .catch() 으로 이어집니다.
    */
    if (error.response && error.response.status === 401) {
      const errorResult = error.response.data
      if (errorResult.code == 341) {
        return await instance.post('/api/auth/reissue').then(async (response) => {
          const result = response.data
          const newAccessToken = result.data.access_token ? result.data.access_token : ''
          common.setSessionValue('accessToken', newAccessToken)
          return await instance({
            ...error.config,
            headers: {
              ...error.config.headers,
              Authorization: `Bearer ${newAccessToken}`,
            },
          })
        })
      } else if (errorResult.code == 334) {
        if (error.response.config.url == '/api/auth/logout') {
          // sessionStorage.removeItem('session:info')
          // window.location.replace('/login')
        } else {
          // window.alert('다른곳에서 접속을 하였습니다.')
          // sessionStorage.removeItem('session:info')
          // window.location.replace('/login')
        }
      } else {
        // window.alert('다른곳에서 접속을 하였습니다.')
        // sessionStorage.removeItem('session:info')
        // window.location.replace('/login')
      }
    } else if (error.response && error.response.status === 400) {
      const errorResult = error.response.data
      if (errorResult.code == 311) {
        // sessionStorage.removeItem('session:info')
        // window.location.replace('/login')
      } else {
        window.alert(`에러가 발생하였습니다.\n[${errorResult.message}]`)
      }
    }
    return Promise.reject(error)
  },
)
export default instance
