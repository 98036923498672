import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import storageSession from 'redux-persist/lib/storage/session'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'

import sidebarShowReducer from './sidebarShowSlice'
import unfoldableReducer from './unfoldableSlice'
import unReadAlarmReducer from './unReadAlarmSlice'

const reducers = combineReducers({
  sidebarShow: sidebarShowReducer,
  unfoldable: unfoldableReducer,
  unReadAlarm: unReadAlarmReducer.reducer,
})

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: [],
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})
export default store
