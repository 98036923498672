import cryptoJs from 'crypto-js'
const common = {}
// 암호화
common.encrypt = (text) => {
  const cipher = cryptoJs.AES.encrypt(
    text,
    cryptoJs.enc.Utf8.parse(process.env.REACT_APP_AES_SALTKEY),
    {
      iv: cryptoJs.enc.Utf8.parse(process.env.REACT_APP_AES_IV),
      padding: cryptoJs.pad.Pkcs7,
      mode: cryptoJs.mode.CBC,
    },
  )
  const regExp = new RegExp('/', 'gi')
  return cipher.toString().replace(regExp, process.env.REACT_APP_AES_SPLIT)
}
// 복호화
common.decrypt = (encryptedText) => {
  const regExp = new RegExp(process.env.REACT_APP_AES_SPLIT, 'gi')
  encryptedText = encryptedText.replace(regExp, '/')
  const decipher = cryptoJs.AES.decrypt(
    encryptedText,
    cryptoJs.enc.Utf8.parse(process.env.REACT_APP_AES_SALTKEY),
    {
      iv: cryptoJs.enc.Utf8.parse(process.env.REACT_APP_AES_IV),
      padding: cryptoJs.pad.Pkcs7,
      mode: cryptoJs.mode.CBC,
    },
  )

  return decipher.toString(cryptoJs.enc.Utf8)
}
common.setSessionModel = (dto) => {
  const sessionInfo = {
    key: dto.id,
    id: dto.login_id,
    name: dto.name,
    su: dto.admin_yn == 1 ? true : false,
    accessToken: dto.access_token,
  }
  const encSessionInfo = common.encrypt(JSON.stringify(sessionInfo))
  sessionStorage.setItem('session:info', encSessionInfo)
  return true
}
common.getSessionValue = (key) => {
  const encSessionInfo = sessionStorage.getItem('session:info')
  if (!!encSessionInfo) {
    const sessionInfoStr = common.decrypt(encSessionInfo)
    const sessionInfo = JSON.parse(sessionInfoStr)
    return sessionInfo[key]
  } else {
    return null
  }
}
common.setSessionValue = (key, value) => {
  const oldSessionInfo = sessionStorage.getItem('session:info')
  if (!!oldSessionInfo) {
    const sessionInfoStr = common.decrypt(oldSessionInfo)
    const sessionInfo = JSON.parse(sessionInfoStr)
    sessionInfo[key] = value
    const encSessionInfo = common.encrypt(JSON.stringify(sessionInfo))
    sessionStorage.setItem('session:info', encSessionInfo)
    return true
  } else {
    return false
  }
}
export default common
