import { createSlice } from '@reduxjs/toolkit'
const intiState = {
  count: 0,
  list: [],
  page: 1,
  limit: 10,
}
const unReadAlarmSlice = createSlice({
  name: 'unReadAlarm',
  initialState: intiState,
  reducers: {
    setCount: (state, action) => {
      state.count = action.payload
    },
    setList: (state, action) => {
      state.list = action.payload
    },
    setPage: (state, action) => {
      state.page = action.payload
    },
    setLimit: (state, action) => {
      state.limit = action.payload
    },
  },
})
export default unReadAlarmSlice
export const { setCount, setList, setPage, setLimit } = unReadAlarmSlice.actions
