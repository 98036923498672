import axios from 'src/assets/js/axiosInstance'
import common from 'src/assets/js/common'
import { useSelector, useDispatch } from 'react-redux'
import { setCount, setList } from 'src/redux/unReadAlarmSlice'
import React, { useState, forwardRef, useImperativeHandle } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  IconButton,
  DialogTitle,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    zIndex: '9000',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    zIndex: '9000',
  },
}))
let AlertPopup = (props, ref) => {
  const dispatch = useDispatch()
  const unReadAlarmList = useSelector((state) => state.unReadAlarm.list)
  /**
   * useState
   */
  const [open, setOpen] = useState(false)
  const [realCrisisModalTitle, setRealCrisisModalTitle] = useState('')
  const [realCrisisModalBody, setRealCrisisModalBody] = useState('')
  const [realCrisisModalAction, setRealCrisisModalAction] = useState('1')
  const [notificationId, setNotificationId] = useState('')
  const [notificationType, setNotificationType] = useState('')
  const [notificationDateTime, setNotificationDateTime] = useState('')
  const [patientId, setPatientId] = useState('')
  const [alertMute, setAlertMute] = useState(false)
  /**
   * 함수 공유
   */
  useImperativeHandle(ref, () => ({
    setOpen: (flag) => {
      setOpen(flag)
    },
    setRealCrisisModalTitle: (title) => {
      setRealCrisisModalTitle(title)
    },
    setRealCrisisModalBody: (body) => {
      setRealCrisisModalBody(body)
    },
    setRealCrisisModalAction: (action) => {
      setRealCrisisModalAction(action)
    },
    setNotificationId: (notificationId) => {
      setNotificationId(notificationId)
    },
    setNotificationType: (notificationType) => {
      setNotificationType(notificationType)
    },
    setNotificationDateTime: (dateTime) => {
      setNotificationDateTime(dateTime)
    },
    setPatientId: (patientId) => {
      setPatientId(patientId)
    },
    setAlertMute: (muteFlag) => {
      muteChange(null, muteFlag)
    },
  }))
  /** 내부 함수 */
  //실제 위기 상황 발생여부 업데이트
  const changeRealCrisisYn = async (value) => {
    setOpen(false)
    if (!!props.alertAfterProcess) {
      props.alertAfterProcess(props.pToastId.current)
    }
    const axiosResult = await axios.post('/api/patient/notification/realCrisisYn', {
      notificationId: notificationId,
      patientId: patientId,
      realCrisisYn: value,
    })
    if (axiosResult.data.code === 201) {
      alert('변경완료')
      if (value === '1') {
        props.setVisibleAssessModal(true)
      }
      getUnReadAlarmCount()
      removeReadAlarm(notificationId, notificationType)
    } else {
      alert('변경실패!')
    }
    if (Object.keys(props.messageObjectRef.current).length > 0) {
      muteChange(null, false)
    } else {
      muteChange(null, true)
    }
  }
  const readNotification = async () => {
    setOpen(false)
    if (!!props.alertAfterProcess) {
      props.alertAfterProcess(props.pToastId.current)
    }
    if (realCrisisModalAction === '0') {
      await axios.post('/api/notification/read', {
        notificationId: notificationId,
      })
      getUnReadAlarmCount()
      removeReadAlarm(notificationId, notificationType)
    }
  }
  const getUnReadAlarmCount = async () => {
    const axiosResult = await axios.get('/api/notification/unread/count', {
      params: {
        institutionId: common.getSessionValue('key'),
      },
    })
    if (axiosResult.data.code === 200) {
      dispatch(setCount(axiosResult.data.data.count))
    }
  }
  const removeReadAlarm = async (notiId, notiType) => {
    const tempList = JSON.parse(JSON.stringify(unReadAlarmList))
    for (let i = 0; i < tempList.length; i++) {
      if (tempList[i].noti_id === Number(notiId) && tempList[i].noti_type === String(notiType)) {
        tempList.splice(i, 1)
        i--
      }
    }
    dispatch(setList(tempList))
  }
  const timeForToday = (value) => {
    const today = new Date()
    const timeValue = new Date(value)

    const betweenTime = Math.floor((today.getTime() - timeValue.getTime()) / 1000 / 60)
    if (betweenTime < 1) return '방금전'
    if (betweenTime < 60) {
      return `${betweenTime}분전`
    }

    const betweenTimeHour = Math.floor(betweenTime / 60)
    if (betweenTimeHour < 24) {
      return `${betweenTimeHour}시간전`
    }

    const betweenTimeDay = Math.floor(betweenTime / 60 / 24)
    if (betweenTimeDay < 365) {
      return `${betweenTimeDay}일전`
    }

    return `${Math.floor(betweenTimeDay / 365)}년전`
  }
  const muteChange = (event, newState) => {
    if (newState === false) {
      newState = false
      props.audioPlayer.current.play()
    } else {
      newState = true
      props.audioPlayer.current.pause()
    }
    setAlertMute(newState)
  }
  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props
    return (
      <DialogTitle sx={{ m: 0, p: 2, color: 'red', fontSize: '3em' }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    )
  }
  return (
    <BootstrapDialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      aria-labelledby="customized-dialog-title"
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          setOpen(false)
          if (Object.keys(props.messageObjectRef.current).length == 0) {
            muteChange(null, true)
          }
        }
      }}
      disableEscapeKeyDown
      style={{ zIndex: '9000' }}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => {
          setOpen(false)
          if (Object.keys(props.messageObjectRef.current).length == 0) {
            muteChange(null, true)
          }
        }}
      >
        {realCrisisModalTitle}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <DialogContentText
          id="alert-dialog-description"
          style={{ textAlign: 'right', fontSize: '1.5em' }}
        >
          {notificationDateTime} ( {timeForToday(notificationDateTime)} )
        </DialogContentText>
        <Typography gutterBottom style={{ fontSize: '2em' }}>
          {realCrisisModalBody}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        {realCrisisModalAction === '1' && (
          <>
            <ToggleButtonGroup
              color="primary"
              value={alertMute}
              exclusive
              onChange={muteChange}
              aria-label="muteButton"
            >
              <ToggleButton value={false}>
                <br /> <div>{alertMute ? '알람 소리 켜기' : '알람 소리 끄기'}</div>
              </ToggleButton>
            </ToggleButtonGroup>
            <Stack
              gap={2}
              direction={{
                xs: 'row-reverse',
                sm: 'row',
              }}
              sx={{
                flexShrink: 0,
                alignSelf: { xs: 'flex-end', sm: 'center' },
              }}
            >
              <Button
                variant="contained"
                style={{ fontSize: '2em', width: '5em' }}
                onClick={() => {
                  changeRealCrisisYn('1')
                }}
              >
                예
              </Button>
              <Button
                variant="outlined"
                style={{ fontSize: '2em', width: '5em' }}
                onClick={() => {
                  changeRealCrisisYn('0')
                }}
              >
                아니오
              </Button>
            </Stack>
          </>
        )}
        {realCrisisModalAction === '2' && (
          <>
            <div></div>
            <Stack
              gap={2}
              direction={{
                xs: 'row-reverse',
                sm: 'row',
              }}
              sx={{
                flexShrink: 0,
                alignSelf: { xs: 'flex-end', sm: 'center' },
              }}
            >
              <Button
                variant="contained"
                style={{ fontSize: '2em', width: '5em' }}
                onClick={() => {
                  changeRealCrisisYn('1')
                }}
              >
                예
              </Button>
              <Button
                variant="outlined"
                style={{ fontSize: '2em', width: '5em' }}
                onClick={() => {
                  changeRealCrisisYn('0')
                }}
              >
                아니오
              </Button>
            </Stack>
          </>
        )}
        {realCrisisModalAction !== '2' && realCrisisModalAction !== '1' && (
          <>
            <Button
              variant="contained"
              style={{ fontSize: '2em', width: '5em' }}
              onClick={() => {
                readNotification()
              }}
            >
              확인
            </Button>
          </>
        )}
      </DialogActions>
    </BootstrapDialog>
  )
}
AlertPopup = forwardRef(AlertPopup)
export default AlertPopup
