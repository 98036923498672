import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import {
  CModalFooter,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CButton,
  CForm,
  CFormTextarea,
  CFormLabel,
  CFormCheck,
  CRow,
  CCol,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCardText,
} from '@coreui/react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { MobileDateTimePicker, koKR, LocalizationProvider } from '@mui/x-date-pickers'
import 'dayjs/locale/ko'
import dayjs from 'dayjs'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'src/assets/js/axiosInstance'
import Slider from '@mui/material/Slider'
import { Box } from '@mui/material'
const PatientAssessPopup = forwardRef((props, ref) => {
  const patientAssessmentNickname = props.patientAssessmentNickname
  const [secondAssessmentUpdateYN, setSecondAssessmentUpdateYN] = useState(-1)
  const [dateTime, setDateTime] = useState(null)
  const [selfCardColor, setSelfCardColor] = useState('light')
  const [othersCardColor, setOthersCardColor] = useState('light')
  const [schemaOptions, setSchemaOptions] = useState({
    actionDateTime: yup.string().required('날짜 및 시간을 입력해주세요.'), // required 설정
    aggressiveBehavior: yup.array().min(1, '최소한 한가지는 체크해주세요.').of(yup.string()), // required 설정
  })
  const schema = yup.object().shape(schemaOptions)
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // 초기값 설정
      actionDateTime: null,
      aggressiveBehavior: [],
      suicidalIntention: '',
      selfSpecificDescription: '',
      actionType: [],
      othersSpecificDescription: '',
      recordedLater: false,
      stableRoomYn: false,
      selfHarmRiskScale: null,
      otherHarmRiskScale: null,
    },
  })
  const watchAggressiveBehavior = watch('aggressiveBehavior', [])
  const watchRecordedLater = watch('recordedLater', false)
  //useEffect 함수
  useEffect(() => {
    if (props.visible === true && !!props.patientAssessPopupActionDatetime) {
      getSecondAssessmentInformation(props.patientPreviousAssessmentId)
    }
  }, [props.visible])
  //2차 환자평가 정보 조회
  const getSecondAssessmentInformation = async (previousAssessmentId) => {
    const data = {
      previousAssessmentId: previousAssessmentId,
    }
    const axiosResult = await axios.post('/api/patient/assess/second/info', data)
    if (axiosResult.data.code === 201 && !!axiosResult.data.data) {
      setSecondAssessmentUpdateYN(1)
      const secondAssessmentInformation = axiosResult.data.data
      //발생시각 입력
      setDateTime(dayjs(secondAssessmentInformation.action_datetime))
      setValue('actionDateTime', dayjs(props.patientAssessPopupActionDatetime).format())
      //자해/자살 행동 입력
      if (secondAssessmentInformation.aggressive_behavior_toward_self === 1) {
        const aggressiveBehaviorValue = getValues('aggressiveBehavior')
        aggressiveBehaviorValue.push('0')
        setValue('aggressiveBehavior', aggressiveBehaviorValue)
        const checkBox = document.getElementById('self')
        checkBox.checked = true
        const checkBoxSuicidalIntention0 = document.getElementById('no')
        checkBoxSuicidalIntention0.removeAttribute('disabled')
        checkBoxSuicidalIntention0.name = 'suicidalIntention'
        const checkBoxSuicidalIntention1 = document.getElementById('have')
        checkBoxSuicidalIntention1.removeAttribute('disabled')
        checkBoxSuicidalIntention1.name = 'suicidalIntention'
        const selfSpecificDescription = document.getElementById('selfSpecificDescription')
        selfSpecificDescription.removeAttribute('disabled')

        setSelfCardColor('')
        setSchemaOptions({
          ...schemaOptions,
          suicidalIntention: yup.string().required('자살의도 여부를 선택해주세요.').nullable(), // required 설정
        })
      } else {
        const aggressiveBehaviorValue = getValues('aggressiveBehavior')
        const filteredValue = aggressiveBehaviorValue.filter((item) => item !== '0')
        setValue('aggressiveBehavior', filteredValue)
        const checkBox = document.getElementById('self')
        checkBox.checked = false
        const checkBoxSuicidalIntention0 = document.getElementById('no')
        checkBoxSuicidalIntention0.disabled = true
        checkBoxSuicidalIntention0.removeAttribute('name')
        const checkBoxSuicidalIntention1 = document.getElementById('have')
        checkBoxSuicidalIntention1.disabled = true
        checkBoxSuicidalIntention1.removeAttribute('name')
        const selfSpecificDescription = document.getElementById('selfSpecificDescription')
        selfSpecificDescription.disabled = true
        setSelfCardColor('light')
        delete schemaOptions.suicidalIntention
        setValue('suicidalIntention', '')
        setValue('selfSpecificDescription', '')
      }
      setTimeout(() => {
        //자살의도 입력
        const checkBoxSuicidalIntention0 = document.getElementById('no')
        const checkBoxSuicidalIntention1 = document.getElementById('have')
        if (secondAssessmentInformation.suicidal_intention === 1) {
          setValue('suicidalIntention', secondAssessmentInformation.suicidal_intention)
          checkBoxSuicidalIntention0.checked = false
          checkBoxSuicidalIntention1.checked = true
        } else if (secondAssessmentInformation.suicidal_intention === 0) {
          setValue('suicidalIntention', secondAssessmentInformation.suicidal_intention)
          checkBoxSuicidalIntention0.checked = true
          checkBoxSuicidalIntention1.checked = false
        }
        //자해/자살 의도 구체적 진술
        setValue('selfSpecificDescription', secondAssessmentInformation.self_specific_description)
        const selfSpecificDescriptionTextArea = document.getElementById('selfSpecificDescription')
        selfSpecificDescriptionTextArea.textContent =
          secondAssessmentInformation.self_specific_description
        //자해/자살 위험척도
        setValue('selfHarmRiskScale', secondAssessmentInformation.self_harm_risk_scale)
        const selfHarmRiskScaleSlider = document.getElementById('selfHarmRiskScale')
        selfHarmRiskScaleSlider
          .querySelectorAll(`span.MuiSlider-mark`)
          .forEach((element, key, parent) => {
            if (key <= secondAssessmentInformation.self_harm_risk_scale) {
              element.classList.remove('css-1tfve6q-MuiSlider-mark')
              element.classList.add('MuiSlider-markActive', 'css-6cwnna-MuiSlider-mark')
            } else {
              element.classList.remove('css-6cwnna-MuiSlider-mark')
              element.classList.add('css-1tfve6q-MuiSlider-mark')
            }
          })
        selfHarmRiskScaleSlider
          .querySelectorAll(`span.MuiSlider-markLabel`)
          .forEach((element, key, parent) => {
            if (key <= secondAssessmentInformation.self_harm_risk_scale) {
              element.classList.remove('css-yafthl-MuiSlider-markLabel')
              element.classList.add('MuiSlider-markLabelActive', 'css-1eoe787-MuiSlider-markLabel')
            } else {
              element.classList.remove('css-1eoe787-MuiSlider-markLabel')
              element.classList.add('css-yafthl-MuiSlider-markLabel')
            }
          })
        selfHarmRiskScaleSlider.querySelector(`span.MuiSlider-track`).style.width = `${
          secondAssessmentInformation.self_harm_risk_scale * 20
        }%`
        const dataSpan = selfHarmRiskScaleSlider.querySelector(`span.MuiSlider-thumb`)
        dataSpan.style.left = `${secondAssessmentInformation.self_harm_risk_scale * 20}%`
        dataSpan
          .querySelector('input')
          .setAttribute('value', `${secondAssessmentInformation.self_harm_risk_scale}`)
        dataSpan
          .querySelector('input')
          .setAttribute('aria-valuenow', `${secondAssessmentInformation.self_harm_risk_scale}`)
        dataSpan.querySelector('span.MuiSlider-valueLabelLabel').textContent =
          secondAssessmentInformation.self_harm_risk_scale
      }, 100)

      //공격적 행동
      if (secondAssessmentInformation.aggressive_behavior_toward_others === 1) {
        const aggressiveBehaviorValue = getValues('aggressiveBehavior')
        aggressiveBehaviorValue.push('1')
        setValue('aggressiveBehavior', aggressiveBehaviorValue)
        const checkBox = document.getElementById('others')
        checkBox.checked = true
        const checkBoxActionType0 = document.getElementById('verbally')
        checkBoxActionType0.removeAttribute('disabled')
        checkBoxActionType0.name = 'actionType'
        const checkBoxActionType1 = document.getElementById('physically')
        checkBoxActionType1.removeAttribute('disabled')
        checkBoxActionType1.name = 'actionType'
        const othersSpecificDescription = document.getElementById('othersSpecificDescription')
        othersSpecificDescription.removeAttribute('disabled')

        setOthersCardColor('')
        setSchemaOptions({
          ...schemaOptions,
          actionType: yup.array().min(1, '행동타입을 선택해주세요.').of(yup.string()), // required 설정
        })
      } else {
        const aggressiveBehaviorValue = getValues('aggressiveBehavior')
        const filteredValue = aggressiveBehaviorValue.filter((item) => item !== '1')
        setValue('aggressiveBehavior', filteredValue)
        const checkBox = document.getElementById('others')
        checkBox.checked = false
        const checkBoxActionType0 = document.getElementById('verbally')
        checkBoxActionType0.disabled = true
        checkBoxActionType0.removeAttribute('name')
        const checkBoxActionType1 = document.getElementById('physically')
        checkBoxActionType1.disabled = true
        checkBoxActionType1.removeAttribute('name')
        const othersSpecificDescription = document.getElementById('othersSpecificDescription')
        othersSpecificDescription.disabled = true

        setOthersCardColor('light')
        delete schemaOptions.actionType
        setValue('actionType', [])
        setValue('othersSpecificDescription', '')
      }
      setTimeout(() => {
        //행동타입 입력
        const checkBoxActionType0 = document.getElementById('verbally')
        const checkBoxActionType1 = document.getElementById('physically')
        if (secondAssessmentInformation.action_type === 2) {
          setValue('actionType', ['0', '1'])
          checkBoxActionType0.checked = true
          checkBoxActionType1.checked = true
        } else if (secondAssessmentInformation.action_type === 1) {
          setValue('actionType', ['1'])
          checkBoxActionType0.checked = false
          checkBoxActionType1.checked = true
        } else if (secondAssessmentInformation.action_type === 0) {
          setValue('actionType', ['0'])
          checkBoxActionType0.checked = true
          checkBoxActionType1.checked = false
        }
        //공격적 행동 구체적 진술
        setValue(
          'othersSpecificDescription',
          secondAssessmentInformation.others_specific_description,
        )
        const othersSpecificDescriptionTextArea = document.getElementById(
          'othersSpecificDescription',
        )
        othersSpecificDescriptionTextArea.textContent =
          secondAssessmentInformation.others_specific_description
        //공격적 행동 위험척도
        setValue('otherHarmRiskScale', secondAssessmentInformation.other_harm_risk_scale)
        const otherHarmRiskScaleSlider = document.getElementById('otherHarmRiskScale')
        otherHarmRiskScaleSlider
          .querySelectorAll(`span.MuiSlider-mark`)
          .forEach((element, key, parent) => {
            if (key <= secondAssessmentInformation.other_harm_risk_scale) {
              element.classList.remove('css-1tfve6q-MuiSlider-mark')
              element.classList.add('MuiSlider-markActive', 'css-6cwnna-MuiSlider-mark')
            } else {
              element.classList.remove('css-6cwnna-MuiSlider-mark')
              element.classList.add('css-1tfve6q-MuiSlider-mark')
            }
          })
        otherHarmRiskScaleSlider
          .querySelectorAll(`span.MuiSlider-markLabel`)
          .forEach((element, key, parent) => {
            if (key <= secondAssessmentInformation.other_harm_risk_scale) {
              element.classList.remove('css-yafthl-MuiSlider-markLabel')
              element.classList.add('MuiSlider-markLabelActive', 'css-1eoe787-MuiSlider-markLabel')
            } else {
              element.classList.remove('css-1eoe787-MuiSlider-markLabel')
              element.classList.add('css-yafthl-MuiSlider-markLabel')
            }
          })
        otherHarmRiskScaleSlider.querySelector(`span.MuiSlider-track`).style.width = `${
          secondAssessmentInformation.other_harm_risk_scale * 20
        }%`
        const dataSpan = otherHarmRiskScaleSlider.querySelector(`span.MuiSlider-thumb`)
        dataSpan.style.left = `${secondAssessmentInformation.other_harm_risk_scale * 20}%`
        dataSpan
          .querySelector('input')
          .setAttribute('value', `${secondAssessmentInformation.other_harm_risk_scale}`)
        dataSpan
          .querySelector('input')
          .setAttribute('aria-valuenow', `${secondAssessmentInformation.other_harm_risk_scale}`)
        dataSpan.querySelector('span.MuiSlider-valueLabelLabel').textContent =
          secondAssessmentInformation.other_harm_risk_scale
      }, 100)
      //후향적 보고 입력
      const checkBoxRecordedLater = document.getElementById('recordedLater')
      if (secondAssessmentInformation.recorded_later === 1) {
        setValue('recordedLater', true)
        checkBoxRecordedLater.checked = true
      } else if (secondAssessmentInformation.recorded_later === 0) {
        setValue('recordedLater', false)
        checkBoxRecordedLater.checked = false
      }
      //안정실 사용 입력
      const checkBoxStableRoomYn = document.getElementById('stableRoomYn')
      if (secondAssessmentInformation.stable_room_yn === 1) {
        setValue('stableRoomYn', true)
        checkBoxStableRoomYn.checked = true
      } else if (secondAssessmentInformation.stable_room_yn === 0) {
        setValue('stableRoomYn', false)
        checkBoxStableRoomYn.checked = false
      }
    } else {
      setSecondAssessmentUpdateYN(0)
      setDateTime(dayjs(props.patientAssessPopupActionDatetime))
      setValue('actionDateTime', dayjs(props.patientAssessPopupActionDatetime).format())
    }
  }
  //환자 평가 저장
  const savePatientAssess = async (data) => {
    if (window.confirm('환자평가를 저장하시겠습니까?')) {
      data.patientId = props.patientAssessmentId
      const axiosResult = await axios.post('/api/patient/assess/save', data)
      if (axiosResult.data.code === 201) {
        setSelfCardColor('light')
        setOthersCardColor('light')
        setSchemaOptions({
          actionDateTime: yup.string().required('날짜 및 시간을 입력해주세요.'), // required 설정
          aggressiveBehavior: yup.array().min(1, '최소한 한가지는 체크해주세요.').of(yup.string()), // required 설정
        })
        reset({
          actionDateTime: null,
          aggressiveBehavior: [],
          suicidalIntention: '',
          selfSpecificDescription: '',
          actionType: [],
          othersSpecificDescription: '',
          recordedLater: false,
          stableRoomYn: false,
        })
        props.setVisible(false)
        props.setPatientAssessmentId('')
        if (!!props.setPatientAssessmentNickname) {
          props.setPatientAssessmentNickname('')
        }
        setDateTime(null)
      }
    }
  }
  //환자 2차 평가 수정
  const savePatientSecondAssess = async (data) => {
    if (window.confirm('환자 2차평가를 저장하시겠습니까?')) {
      data.previousAssessmentId = props.patientPreviousAssessmentId
      data.patientId = props.patientAssessmentId
      data.assessmentType = 2
      const axiosResult = await axios.post('/api/patient/assess/second/save', data)
      if (axiosResult.data.code === 201) {
        setSelfCardColor('light')
        setOthersCardColor('light')
        setSchemaOptions({
          actionDateTime: yup.string().required('날짜 및 시간을 입력해주세요.'), // required 설정
          aggressiveBehavior: yup.array().min(1, '최소한 한가지는 체크해주세요.').of(yup.string()), // required 설정
        })
        reset({
          actionDateTime: null,
          aggressiveBehavior: [],
          suicidalIntention: '',
          selfSpecificDescription: '',
          actionType: [],
          othersSpecificDescription: '',
          recordedLater: false,
          stableRoomYn: false,
        })
        props.setVisible(false)
        props.setPatientAssessmentId('')
        if (!!props.setPatientAssessmentNickname) {
          props.setPatientAssessmentNickname('')
        }
        setDateTime(null)
      }
    }
  }
  //액션타입 메시지 세팅
  const onClickAggressiveBehavior = (e) => {
    if (e.target.checked && e.target.id == 'self') {
      setSelfCardColor('')
      setSchemaOptions({
        ...schemaOptions,
        suicidalIntention: yup.string().required('자살의도 여부를 선택해주세요.').nullable(), // required 설정
        selfHarmRiskScale: yup.string().required('자해 위험 척도를 선택해주세요.').nullable(), // required 설정
      })
    } else if (!e.target.checked && e.target.id == 'self') {
      setSelfCardColor('light')
      delete schemaOptions.suicidalIntention
      delete schemaOptions.selfHarmRiskScale
      setValue('suicidalIntention', '')
      setValue('selfSpecificDescription', '')
      setValue('selfHarmRiskScale', null)
    } else if (e.target.checked && e.target.id == 'others') {
      setOthersCardColor('')
      setSchemaOptions({
        ...schemaOptions,
        actionType: yup.array().min(1, '행동타입을 선택해주세요.').of(yup.string()), // required 설정
        otherHarmRiskScale: yup.string().required('타해 위험 척도를 선택해주세요.').nullable(), // required 설정
      })
    } else if (!e.target.checked && e.target.id == 'others') {
      setOthersCardColor('light')
      delete schemaOptions.actionType
      delete schemaOptions.otherHarmRiskScale
      setValue('actionType', [])
      setValue('othersSpecificDescription', '')
      setValue('otherHarmRiskScale', null)
    } else {
      setSelfCardColor('light')
      setOthersCardColor('light')
      setSchemaOptions({
        actionDateTime: yup.string().required('날짜 및 시간을 입력해주세요.'), // required 설정
        aggressiveBehavior: yup.array().min(1, '최소한 한가지는 체크해주세요.').of(yup.string()), // required 설정
      })
    }
  }
  //모달 닫기
  const onClose = () => {
    setSelfCardColor('light')
    setOthersCardColor('light')
    setSchemaOptions({
      actionDateTime: yup.string().required('날짜 및 시간을 입력해주세요.'), // required 설정
      aggressiveBehavior: yup.array().min(1, '최소한 한가지는 체크해주세요.').of(yup.string()), // required 설정
    })
    reset({
      actionDateTime: null,
      aggressiveBehavior: [],
      suicidalIntention: '',
      selfSpecificDescription: '',
      actionType: [],
      othersSpecificDescription: '',
      recordedLater: false,
      stableRoomYn: false,
    })
    props.setVisible(false)
    props.setPatientAssessmentId('')
    if (!!props.setPatientAssessmentNickname) {
      props.setPatientAssessmentNickname('')
    }
    setDateTime(null)
  }
  //상위 컴포넌트에 함수 공유
  useImperativeHandle(ref, () => ({
    handleSubmit,
    onClose,
  }))
  return (
    <CModal
      alignment="center"
      backdrop="static"
      size="lg"
      visible={props.visible}
      onClose={onClose}
    >
      <CModalHeader>
        <CModalTitle>환자평가{props.assessmentType === 2 ? ' (2차)' : ''}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CForm>
          <CRow className="mb-3">
            <CCol sm={12}>
              <h2 style={{ fontWeight: 'bolder' }}>{patientAssessmentNickname}</h2>
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CCol sm={2}>
              <CFormLabel
                htmlFor="actionDateTime"
                className="col-sm-10 col-form-label"
                style={{ fontWeight: 'bold' }}
              >
                발생시각
              </CFormLabel>
            </CCol>
            <CCol sm={9}>
              <Controller
                control={control}
                name="actionDateTime"
                render={({ field }) => (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="ko"
                    localeText={koKR.components.MuiLocalizationProvider.defaultProps.localeText}
                  >
                    {watchRecordedLater === false && (
                      <MobileDateTimePicker
                        format="YYYY-MM-DD HH:mm"
                        label="시간을 입력해주세요."
                        views={['hours', 'minutes']}
                        value={dateTime}
                        disableFuture
                        // onOpen={(e) => {
                        //   field.onChange(dayjs().set('hour', 1).set('minutes', 0).set('second', 0))
                        //   setDateTime(dayjs().set('hour', 1).set('minutes', 0).set('second', 0))
                        // }}
                        onChange={(date) => {
                          field.onChange(date)
                          setDateTime(date)
                        }}
                      />
                    )}
                    {watchRecordedLater === true && (
                      <MobileDateTimePicker
                        format="YYYY-MM-DD HH:mm"
                        label="시간을 입력해주세요."
                        value={dateTime}
                        disableFuture
                        onChange={(date) => {
                          field.onChange(date)
                          setDateTime(date)
                        }}
                      />
                    )}
                  </LocalizationProvider>
                )}
              />
              {errors.actionDateTime && (
                <div style={{ marginTop: '5px', fontSize: 'small', color: '#e55353' }}>
                  {errors.actionDateTime.message}
                </div>
              )}
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CCol sm={6}>
              <CCard color={selfCardColor}>
                <CCardHeader>
                  <CFormCheck
                    id="self"
                    label="자해/자살 행동"
                    value="0"
                    onClick={(e) => onClickAggressiveBehavior(e)}
                    {...register('aggressiveBehavior')}
                  />
                  {errors.aggressiveBehavior && (
                    <div style={{ marginTop: '5px', fontSize: 'small', color: '#e55353' }}>
                      {errors.aggressiveBehavior.message}
                    </div>
                  )}
                </CCardHeader>
                {watchAggressiveBehavior.indexOf('0') !== -1 && (
                  <CCardBody>
                    <CRow className="mb-3">
                      <CFormLabel
                        htmlFor="suicidalIntention"
                        className="col-sm-3 col-form-label"
                        style={{ fontWeight: 'bold' }}
                      >
                        자살의도
                      </CFormLabel>
                      <CCol sm={9}>
                        <span>
                          <CFormCheck
                            button={{ color: 'info', variant: 'outline' }}
                            type="radio"
                            id="no"
                            autoComplete="off"
                            label="없었음"
                            value="0"
                            {...register('suicidalIntention')}
                          />
                        </span>
                        <span style={{ marginLeft: '10px' }}>
                          <CFormCheck
                            button={{ color: 'danger', variant: 'outline' }}
                            type="radio"
                            id="have"
                            autoComplete="off"
                            label="있었음"
                            value="1"
                            {...register('suicidalIntention')}
                          />
                        </span>
                        {errors.suicidalIntention && (
                          <div style={{ marginTop: '5px', fontSize: 'small', color: '#e55353' }}>
                            {errors.suicidalIntention.message}
                          </div>
                        )}
                      </CCol>
                    </CRow>
                    <CRow className="mb-3">
                      <CCol sm={12}>
                        <CFormTextarea
                          id="selfSpecificDescription"
                          label="구체적 서술"
                          rows={3}
                          text=""
                          placeholder="필요한 경우, 구체적인 서술을 해주세요."
                          {...register('selfSpecificDescription')}
                        ></CFormTextarea>
                      </CCol>
                    </CRow>
                    <CRow className="mb-3">
                      <CCol sm={12}>
                        <CFormLabel
                          htmlFor="selfHarmRiskScale"
                          className="col-sm-3 col-form-label"
                          style={{ fontWeight: 'bold' }}
                        >
                          위험 척도
                        </CFormLabel>
                      </CCol>
                      <CCol sm={12} style={{ textAlign: 'center' }}>
                        <Slider
                          id="selfHarmRiskScale"
                          {...register('selfHarmRiskScale')}
                          sx={{ width: '80%' }}
                          aria-label="selfHarmRiskScale"
                          defaultValue={0}
                          step={1}
                          valueLabelDisplay="auto"
                          marks={[
                            {
                              value: 0,
                              label: '0',
                            },
                            {
                              value: 1,
                              label: '1',
                            },
                            {
                              value: 2,
                              label: '2',
                            },
                            {
                              value: 3,
                              label: '3',
                            },
                            {
                              value: 4,
                              label: '4',
                            },
                            {
                              value: 5,
                              label: '5',
                            },
                          ]}
                          shiftStep={1}
                          min={0}
                          max={5}
                          color="error"
                        />
                      </CCol>
                      <CCol sm={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Box sx={{ typography: 'body2', color: '#848484' }}>전혀 없음</Box>
                          <Box sx={{ typography: 'body2', color: '#848484' }}>최고의 위험</Box>
                        </Box>
                      </CCol>
                    </CRow>
                  </CCardBody>
                )}
                {watchAggressiveBehavior.indexOf('0') == -1 && (
                  <CCardBody>
                    <CRow className="mb-3">
                      <CFormLabel
                        htmlFor="suicidalIntention"
                        className="col-sm-3 col-form-label"
                        style={{ fontWeight: 'bold' }}
                      >
                        자살의도
                      </CFormLabel>
                      <CCol sm={9}>
                        <span>
                          <CFormCheck
                            button={{ color: 'info', variant: 'outline' }}
                            type="radio"
                            id="no"
                            autoComplete="off"
                            label="없었음"
                            value="0"
                            disabled
                          />
                        </span>
                        <span style={{ marginLeft: '10px' }}>
                          <CFormCheck
                            button={{ color: 'danger', variant: 'outline' }}
                            type="radio"
                            id="have"
                            autoComplete="off"
                            label="있었음"
                            value="1"
                            disabled
                          />
                        </span>
                        {errors.suicidalIntention && (
                          <div style={{ marginTop: '5px', fontSize: 'small', color: '#e55353' }}>
                            {errors.suicidalIntention.message}
                          </div>
                        )}
                      </CCol>
                    </CRow>
                    <CRow className="mb-3">
                      <CCol sm={12}>
                        <CFormTextarea
                          id="selfSpecificDescription"
                          label="구체적 서술"
                          rows={3}
                          text=""
                          placeholder="필요한 경우, 구체적인 서술을 해주세요."
                          disabled
                        ></CFormTextarea>
                      </CCol>
                    </CRow>
                    <CRow className="mb-3">
                      <CCol sm={12}>
                        <CFormLabel
                          htmlFor="selfHarmRiskScale"
                          className="col-sm-3 col-form-label"
                          style={{ fontWeight: 'bold' }}
                        >
                          위험 척도
                        </CFormLabel>
                      </CCol>
                      <CCol sm={12} style={{ textAlign: 'center' }}>
                        <Slider
                          id="selfHarmRiskScale"
                          {...register('selfHarmRiskScale')}
                          sx={{ width: '80%' }}
                          aria-label="selfHarmRiskScale"
                          defaultValue={0}
                          step={1}
                          valueLabelDisplay="auto"
                          disabled
                          marks={[
                            {
                              value: 0,
                              label: '0',
                            },
                            {
                              value: 1,
                              label: '1',
                            },
                            {
                              value: 2,
                              label: '2',
                            },
                            {
                              value: 3,
                              label: '3',
                            },
                            {
                              value: 4,
                              label: '4',
                            },
                            {
                              value: 5,
                              label: '5',
                            },
                          ]}
                          shiftStep={1}
                          min={0}
                          max={5}
                          color="error"
                        />
                      </CCol>
                      <CCol sm={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Box sx={{ typography: 'body2', color: '#848484' }}>전혀 없음</Box>
                          <Box sx={{ typography: 'body2', color: '#848484' }}>최고의 위험</Box>
                        </Box>
                      </CCol>
                    </CRow>
                  </CCardBody>
                )}
              </CCard>
            </CCol>
            <CCol sm={6}>
              <CCard color={othersCardColor}>
                <CCardHeader>
                  <CFormCheck
                    id="others"
                    label="공격적 행동"
                    value="1"
                    onClick={(e) => onClickAggressiveBehavior(e)}
                    {...register('aggressiveBehavior')}
                  />
                  {errors.aggressiveBehavior && (
                    <div style={{ marginTop: '5px', fontSize: 'small', color: '#e55353' }}>
                      {errors.aggressiveBehavior.message}
                    </div>
                  )}
                </CCardHeader>
                {watchAggressiveBehavior.indexOf('1') !== -1 && (
                  <CCardBody>
                    <CRow className="mb-3">
                      <CFormLabel
                        htmlFor="actionType"
                        className="col-sm-3 col-form-label"
                        style={{ fontWeight: 'bold' }}
                      >
                        행동타입
                      </CFormLabel>
                      <CCol sm={9}>
                        <span>
                          <CFormCheck
                            button={{ color: 'info', variant: 'outline' }}
                            id="verbally"
                            autoComplete="off"
                            label="언어적"
                            value="0"
                            {...register('actionType')}
                          />
                        </span>
                        <span style={{ marginLeft: '10px' }}>
                          <CFormCheck
                            button={{ color: 'danger', variant: 'outline' }}
                            id="physically"
                            autoComplete="off"
                            label="신체적"
                            value="1"
                            {...register('actionType')}
                          />
                        </span>
                        {errors.actionType && (
                          <div style={{ marginTop: '5px', fontSize: 'small', color: '#e55353' }}>
                            {errors.actionType.message}
                          </div>
                        )}
                      </CCol>
                    </CRow>
                    <CRow className="mb-3">
                      <CCol sm={12}>
                        <CFormTextarea
                          id="othersSpecificDescription"
                          label="구체적 서술"
                          rows={3}
                          text=""
                          placeholder="필요한 경우, 구체적인 서술을 해주세요."
                          {...register('othersSpecificDescription')}
                        ></CFormTextarea>
                      </CCol>
                    </CRow>
                    <CRow className="mb-3">
                      <CCol sm={12}>
                        <CFormLabel
                          htmlFor="otherHarmRiskScale"
                          className="col-sm-3 col-form-label"
                          style={{ fontWeight: 'bold' }}
                        >
                          위험 척도
                        </CFormLabel>
                      </CCol>
                      <CCol sm={12} style={{ textAlign: 'center' }}>
                        <Slider
                          id="otherHarmRiskScale"
                          {...register('otherHarmRiskScale')}
                          sx={{ width: '80%' }}
                          aria-label="otherHarmRiskScale"
                          defaultValue={0}
                          step={1}
                          valueLabelDisplay="auto"
                          marks={[
                            {
                              value: 0,
                              label: '0',
                            },
                            {
                              value: 1,
                              label: '1',
                            },
                            {
                              value: 2,
                              label: '2',
                            },
                            {
                              value: 3,
                              label: '3',
                            },
                            {
                              value: 4,
                              label: '4',
                            },
                            {
                              value: 5,
                              label: '5',
                            },
                          ]}
                          shiftStep={1}
                          min={0}
                          max={5}
                          color="error"
                        />
                      </CCol>
                      <CCol sm={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Box sx={{ typography: 'body2', color: '#848484' }}>전혀 없음</Box>
                          <Box sx={{ typography: 'body2', color: '#848484' }}>최고의 위험</Box>
                        </Box>
                      </CCol>
                    </CRow>
                  </CCardBody>
                )}
                {watchAggressiveBehavior.indexOf('1') == -1 && (
                  <CCardBody>
                    <CRow className="mb-3">
                      <CFormLabel
                        htmlFor="actionType"
                        className="col-sm-3 col-form-label"
                        style={{ fontWeight: 'bold' }}
                      >
                        행동타입
                      </CFormLabel>
                      <CCol sm={9}>
                        <span>
                          <CFormCheck
                            button={{ color: 'info', variant: 'outline' }}
                            id="verbally"
                            autoComplete="off"
                            label="언어적"
                            value="0"
                            disabled
                          />
                        </span>
                        <span style={{ marginLeft: '10px' }}>
                          <CFormCheck
                            button={{ color: 'danger', variant: 'outline' }}
                            id="physically"
                            autoComplete="off"
                            label="신체적"
                            value="1"
                            disabled
                          />
                        </span>
                      </CCol>
                    </CRow>
                    <CRow className="mb-3">
                      <CCol sm={12}>
                        <CFormTextarea
                          id="othersSpecificDescription"
                          label="구체적 서술"
                          rows={3}
                          text=""
                          placeholder="필요한 경우, 구체적인 서술을 해주세요."
                          disabled
                        ></CFormTextarea>
                      </CCol>
                    </CRow>
                    <CRow className="mb-3">
                      <CCol sm={12}>
                        <CFormLabel
                          htmlFor="otherHarmRiskScale"
                          className="col-sm-3 col-form-label"
                          style={{ fontWeight: 'bold' }}
                        >
                          위험 척도
                        </CFormLabel>
                      </CCol>
                      <CCol sm={12} style={{ textAlign: 'center' }}>
                        <Slider
                          id="otherHarmRiskScale"
                          {...register('otherHarmRiskScale')}
                          sx={{ width: '80%' }}
                          aria-label="otherHarmRiskScale"
                          defaultValue={0}
                          step={1}
                          valueLabelDisplay="auto"
                          disabled
                          marks={[
                            {
                              value: 0,
                              label: '0',
                            },
                            {
                              value: 1,
                              label: '1',
                            },
                            {
                              value: 2,
                              label: '2',
                            },
                            {
                              value: 3,
                              label: '3',
                            },
                            {
                              value: 4,
                              label: '4',
                            },
                            {
                              value: 5,
                              label: '5',
                            },
                          ]}
                          shiftStep={1}
                          min={0}
                          max={5}
                          color="error"
                        />
                      </CCol>
                      <CCol sm={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Box sx={{ typography: 'body2', color: '#848484' }}>전혀 없음</Box>
                          <Box sx={{ typography: 'body2', color: '#848484' }}>최고의 위험</Box>
                        </Box>
                      </CCol>
                    </CRow>
                  </CCardBody>
                )}
              </CCard>
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CCol sm={12}>
              <CFormCheck
                inline
                id="recordedLater"
                label="후향적 보고 시 체크"
                {...register('recordedLater')}
              />
              <CFormCheck
                inline
                id="stableRoomYn"
                label="안정실 사용"
                {...register('stableRoomYn')}
              />
            </CCol>
          </CRow>
        </CForm>
      </CModalBody>
      <CModalFooter>
        {secondAssessmentUpdateYN === -1 && (
          <CButton color="dark" onClick={handleSubmit(savePatientAssess)}>
            확인
          </CButton>
        )}
        {secondAssessmentUpdateYN === 0 && (
          <CButton color="dark" onClick={handleSubmit(savePatientSecondAssess)}>
            확인
          </CButton>
        )}
        {secondAssessmentUpdateYN === 1 && (
          <CButton color="dark" onClick={handleSubmit(savePatientSecondAssess)}>
            수정
          </CButton>
        )}
        <CButton color="secondary" onClick={onClose}>
          취소
        </CButton>
      </CModalFooter>
    </CModal>
  )
})
PatientAssessPopup.displayName = 'PatientAssessPopup'
export default PatientAssessPopup
